<template>
    <div class="list">
        <div v-for="board in boardData" :key="`board${board.wr_id}`"  @click.self="goDetail(board.wr_id)" class="l_wrap">
            <div class="thumbnail" @click="goDetail(board.wr_id)">
                <img :src="returnImageUrl(board) === '' ? '@/assets/image/rebuild/img_2.jpg' : returnImageUrl(board)" class="img">
                <div class="badge" :class="{'finish' : board.sbl_status === 2}">{{returnStatusText(board.sbl_status)}}</div> <!--마감인경우 클래스 finish 추가-->
            </div>
            <div class="content" @click.self="goDetail(board.wr_id)">
                <div class="category" @click.self="goDetail(board.wr_id)">{{returnCategoryName(board.bc_idx)}}</div>
                <div class="title" @click.self="goDetail(board.wr_id)">{{board.wr_subject}}</div>
                <div class="info" @click.self="goDetail(board.wr_id)">
<!--                    <div class="like">555555</div>-->
                    <board-like-component :boardType="'letterListLike'" :boardData="board"></board-like-component>
                    <div class="comment" @click.self="goDetail(board.wr_id)">{{returnCount(board.CommentCount, 999)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardLikeComponent from "@/components/board/BoardLikeComponent";
    import imageOption from "@/mixins/imageOption";
    import {mapState, mapGetters} from "vuex"
    export default {
        name: "BoardListLetterLayout",
        components: {
            BoardLikeComponent
        },
        mixins: [imageOption],
        props: {
            boardData: {
                type: Array,
                default: () => {
                    return []
                },
            },
            total: {
                type: Number,
                default: 0,
            },
            categorys: {
                type: Array,
                default: () => {
                    return []
                },
            },
        },
        data() {
            return {
                categoryList: [],
            }
        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        created() {
            this.getCategory();
        },
        mounted() {
        },
        methods: {
            getCategory() {
                this.$api.$board.getJobCategory().then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.categoryList = res.List;
                    }
                })
            },
            returnStatusText(status) {
                if(status === 1) {
                    return '진행중'
                }else if(status === 2) {
                    return '마감'
                }else{
                    return '취소'
                }
            },
            returnCategoryName(bcIdx) {
                let cateIndex = this.categoryList.findIndex(cate => cate.mjc_idx === bcIdx);
                if(cateIndex > -1) {
                    return this.categoryList[cateIndex].mjc_job;
                }
            },
            returnCount(count, maxCount) {
                if(maxCount < count) {
                    return maxCount+'+';
                }
                return count
            },
            goDetail(idx) {
                this.$router.push(`/letter/${idx}`);
            },
            returnImageUrl(board) {
                return board.hasOwnProperty('Files') && board.Files.length > 0 ? `${board.Files[0].org_url}?${this.setImageOptions(197, 197, 'png')}` : '';
            },
        },
    }
</script>

<style scoped>

</style>