<template>
    <component :is="component" v-if="component" :boardData="boardData"  />
</template>

<script>
    import boardTemplateUtil from "@/components/board/boardTemplateUtil"
    import boardFilterUtil from "@/components/board/boardFilterUtil"
    import EventBus from "@/utils/event-bus";

    export default {
        name: "BoardLikeComponent",
        mixins: [boardTemplateUtil, boardFilterUtil, ],
        provide: function () {
            return {
                runLikeFunc: this.runLikeFunc,
            }
        },
        inject: ['afterLikeFunc'],
        props: {
            boardType: {
                type: String,
            },
            boardData: {
                default: {},
            },
        },
        data() {
            return {
                likeLayout: '',
                component: null,
                likeFunc: null,
                keyName: null,
            }
        },
        created() {
            this.initConfig();
        },
        computed: {
            loader() {
                if (!this.likeLayout) {
                    return null
                }
                return () => import(`@/template/board/like/${this.likeLayout}`)
            },
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/board/like/BoardLikeDefaultLayout')
                })
        },
        methods: {
            initConfig() {
                let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
                this.likeLayout = boardTemplateConfig.likeLayout;
                let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
                this.likeFunc = boardFilterConfig.likeFunc;
                this.keyName = boardFilterConfig.keyName
                /*this.getFunc = boardFilterConfig.getFunc;
                this.getCategoryFunc = boardFilterConfig.getCategoryFunc;
                this.detailApiParamSet = boardFilterConfig.detailApiParamSet;*/
            },
            runLikeFunc() {
                let params = {
                    wr_type: 'post',
                    bl_status: 1,
                }
                params[this.keyName] = this.boardData[this.keyName];
                this.$api.$board[this.likeFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnLikeReturnType(res.Info.type);
                    if(type === true) {
                        if(this.afterLikeFunc) {
                            this.afterLikeFunc();
                        }
                        EventBus.$emit('boardListAfterFunc', this.keyName ,this.boardData[this.keyName])
                    }
                })
            },
            returnLikeReturnType(type) {
                switch (type) {
                    case 1:
                        return true;
                    default:
                        return '좋아요 등록에 실패하였습니다. 잠시 후 다시 시도해주세요.'
                }
            },

        },
    }
</script>

<style scoped>

</style>